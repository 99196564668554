@font-face {
    font-family: 'Favoritpro';
    src: url('~/src/assets/fonts/FavoritPro-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Favoritpro';
    src: url('~/src/assets/fonts/FavoritPro-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Favoritpro';
    src: url('~/src/assets/fonts/FavoritPro-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'GT Pressura LCGV';
    src: url('~/src/assets/fonts/GT-Pressura-LCGV-Extended-Light.otf') format('otf');
    font-weight: 200;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'GT Pressura LCGV';
    src: url('~/src/assets/fonts/GT-Pressura-LCGV-Extended-Text.otf') format('otf');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'GT Pressura LCGV';
    src: url('~/src/assets/fonts/GT-Pressura-LCGV-Extended-Regular.otf') format('otf');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'GT Pressura LCGV';
    src: url('~/src/assets/fonts/GT-Pressura-LCGV-Extended-Medium.otf') format('otf');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}
